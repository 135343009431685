import React, { useState } from 'react'
import "./footer.css"

const Footer = () => {
    window.addEventListener("scroll", function () {
        const header = document.querySelector(".header");
        if(this.scrollY >= 80) header.classList.add ("scroll-header");
        else header.classList.remove("scroll-header");
    })

    const[activeNav, setActiveNav] = useState("home");
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">Matlock</h1>

            <ul className="footer__list">
                <li>
                    <a href="/" onClick={() => setActiveNav('#')} className=
                        {activeNav === "#" ? "footer__link" : "footer__link"}>Home</a>
                </li>

                <li>
                    <a href="#about" onClick={() => setActiveNav('#about')} className=
                        {activeNav === "#about" ? "footer__link" : "footer__link"}>Instructor</a>
                </li>

                <li>
                    <a href="#services" onClick={() => setActiveNav('#services')} className=
                        {activeNav === "#services" ? "footer__link" : "footer__link"}>Programs</a>
                </li>
                <li>
                    <a href="#qualification" onClick={() => setActiveNav('#qualification')} className=
                        {activeNav === "#qualification" ? "footer__link" : "footer__link"}>Schedule</a>
                </li>
            </ul>

            <div className="footer__social">
                <a href="https://www.instagram.com/matlockbjj" className="footer__social-link" rel="noreferrer" target="_blank">
                <i class="bx bxl-instagram"></i>
                </a>

                <a href="https://www.facebook.com/matlockbjj" className="footer__social-link" rel="noreferrer" target="_blank">
                <i class="bx bxl-facebook"></i>
                </a>
            </div>
            <span className="footer__copy">&#169; Matlock. All right reserved.</span>
        </div>
    </footer>
  )
}

export default Footer