import React from 'react'
import Two from '../../assets/4.jpg'

const TwoMembership = () => {
  return (
    <div className="memberships__content">
        <h3 className="memberships__title">Kid Monthly Membership</h3>

        <div className="memberships__box">
          <img src={Two} alt="" className="memberships__img"/>
        </div>
        
        <stripe-buy-button
        style={{marginTop: '30px'}}
                  buy-button-id="buy_btn_1N6hCLAFFZn0NtX4qVktgMgn"
                  publishable-key="pk_live_51JcDQEAFFZn0NtX4NGzCVFYCq6uNPyDYsJ7TbeqiZJlwuhYRUhSw9QvM6Y1YGN3U9AzQUuu2qC1XjZxMazb25fT600wABPk2LA"
                >
                </stripe-buy-button>
    </div>
  )
}

export default TwoMembership