import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.instagram.com/getlostidky" className="home__social-icon" rel="noreferrer" target="_blank">
            <i class="uil uil-instagram"></i>
        </a>

        <a href="https://www.twitter.com/pleasuresdotbnb" className="home__social-icon" rel="noreferrer" target="_blank">
        <i class="uil uil-facebook"></i>
        </a>

    </div>
  )
}

export default Social