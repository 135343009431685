import React, { useRef } from 'react'
import "./waiver.css"
import emailjs from '@emailjs/browser'

const Waiver = () => {
    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xsu6xw4', 'template_85eq5m9', form.current, 'YXb_oX4WPAAmIkQBc')
        .then((result) => {
            alert("Form sent successfully!");
        }, (error) => {
            console.log(error.text);
        });
      } 

  var curr = new Date();
      curr.setDate(curr.getDate());
      var date = curr.toISOString().substring(0,10);

  return (
    <section className="services section" id="services">
        <h2 className="section__title">Waiver</h2>

        <div className="waiver__container containers grid">
            
            <div className="waiver__content">
                <div>
                    <h3 className="waiver__title">NON-MEMBER CONSENT FORM</h3>
                    <p>ASSUMPTION OF RISK</p>
                    <p style={{marginTop: '20px'}}>I know the risks involved in Brazilian Jiu Jitsu, MMA, Muay Thai, Boxing, and Judo and understand that unforeseen and unexpected dangers may arise while participating in such activities at MATLOCK BRAZILIAN JIU JITSU. I, hereby, release and hold harmless, MATLOCK BRAZILIAN JIU JITSU, from any damages, claims, or judgments that may result from injury to my child or myself while on site or in the care of MATLOCK BRAZILIAN JIU JITSU in relation to the above activities or any other activities of MATLOCK BRAZILIAN JIU JITSU. I also authorize MATLOCK BRAZILIAN JIU JITSU to administer first aid in my absence, to my child, or, if present, to myself.
                    <br/><br/>
                    You also agree that by signing this waiver that the from the date of signing begins your two week free trial. After your two week free trial you must have signed up for a Matlock Brazilian Jiu Jitsu Membership.
                    <br/><br/>
                    I HAVE READ, UNDERSTAND, AND AGREE TO HOLD MATLOCK BRAZILIAN JIU JITSU HARMLESS OF ALL POLICIES LISTED ABOVE.
                    <br/><br/>
                    Disclaimer: Matlock Brazilian Jiu Jitsu reserves the right as a business to use random candid photographs and video at any time of any of our students, parents, and visitors in an effort to promote the facility. These photographs and video may be used in advertisements or published on Facebook or other social media pages for Matlock Brazilian Jiu Jitsu for the depiction of special events at the facility or everyday activity.
                    </p>
                </div>
                
                <form ref={form} onSubmit={sendEmail} className="waiver__form">
                    <div className="two_container">
                        <div>
                    <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">Name</label>
                        <input
                            type="text"
                            name="name"
                            className="waiver__form-input"
                            placeholder="Last name, First name"
                        />
                    </div>

                    <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">E-Mail</label>
                        <input
                            type="email"
                            name="email"
                            className="waiver__form-input"
                            placeholder="Insert your email."
                        />
                    </div>

                    <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">Contact Number</label>
                        <input
                            type="tel"
                            name="tel"
                            className="waiver__form-input"
                            placeholder="Insert your number."
                        />
                    </div>
                    </div>
                   
                        <div>
                   <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">Emergency Contact Name</label>
                        <input
                            type="text"
                            name="emergency_name"
                            className="waiver__form-input"
                            placeholder="Emergency Contact Name"
                        />
                    </div>

                    <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">Emergency Contact Number</label>
                        <input
                            type="tel"
                            name="emergency_tel"
                            className="waiver__form-input"
                            placeholder="Emergency Contact Number."
                        />
                    </div>

                    <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">Relationship to Signee</label>
                        <input
                            type="text"
                            name="relationship"
                            className="waiver__form-input"
                            placeholder="Relationship to Signee."
                        />
                    </div>
                    </div>
                    </div>
                 
                 <hr style={{marginBottom: '30px'}}/>

                    <div className="waiver__form-div">
                        <label htmlFor="" className="waiver__form-tag">Today's Date</label>
                        <input
                            type="date"
                            name="today_date"
                            defaultValue={date}
                            className="waiver__form-input"
                            placeholder="Relationship to Signee."
                        />
                    </div>

                    <div className="waiver__form-div">
                       
                        <input
                            type="checkbox"
                            name="agree"
                            value="Yes"
                            className="waiver__form-input"
                        />
                          <label class="form-check-label">I agree to the terms and condition.</label>
                    </div>

                    <button className="buttons buttons--flex">
                        Send Message
                            <svg
                            class="buttons__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            value="send"
                            >
                            <path
                                d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                                fill="var(--title-color)"
                            ></path>
                            <path
                                d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                                fill="var(--title-color)"
                            ></path>
                            </svg>
                    </button>
                </form>
            </div>
        </div>
    </section>
  )
}

export default Waiver