import React from 'react'
import Three from '../../assets/3.jpg'

const ThreeMembership = () => {
  return (
    <div className="memberships__content">
        <h3 className="memberships__title">College Monthly <br/>Membership </h3>

        <div className="memberships__box">
          <img src={Three} alt="" className="memberships__img"/>
        </div>
        
        <stripe-buy-button
        style={{marginTop: '30px'}}
                  buy-button-id="buy_btn_1N6hDsAFFZn0NtX4YqZaG1W9"
                  publishable-key="pk_live_51JcDQEAFFZn0NtX4NGzCVFYCq6uNPyDYsJ7TbeqiZJlwuhYRUhSw9QvM6Y1YGN3U9AzQUuu2qC1XjZxMazb25fT600wABPk2LA"
                >
                </stripe-buy-button>
    </div>
  )
}

export default ThreeMembership