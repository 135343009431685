import React, { useState } from 'react'
import './header.css'

const Header = () => {
    window.addEventListener("scroll", function () {
        const header = document.querySelector(".header");
        if(this.scrollY >= 80) header.classList.add ("scroll-header");
        else header.classList.remove("scroll-header");
    })

    const[Toggle, showMenu] = useState(false);
    const[activeNav, setActiveNav] = useState("home");
  return (
    <header className="header">
        <nav className="nav container">
            <a href="index.html" className="nav__logo">Matlock Brazilian Jiu Jitsu</a>

            <div className={Toggle ? "nav__menu show-menu":"nav__menu"}>
                <ul className="nav__list grid">
                    <li className="nav__item">
                        <a href="#home" onClick={() => setActiveNav('#home')} className=
                        {activeNav === "#home" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-estate nav__icon"></i>Home
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#about" onClick={() => setActiveNav('#about')} className=
                        {activeNav === "#about" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-user nav__icon"></i>Our Instructor
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#services" onClick={() => setActiveNav('#services')} className=
                        {activeNav === "#services" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-file-alt nav__icon"></i>Programs
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#qualification" onClick={() => setActiveNav('#qualification')} className=
                        {activeNav === "#qualification" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-file-alt nav__icon"></i>Schedule
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#waiver" onClick={() => setActiveNav('#waiver')} className=
                        {activeNav === "/waiver" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-file-alt nav__icon"></i>Waiver
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#memberships" onClick={() => setActiveNav('#memberships')} className=
                        {activeNav === "/memberships" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-file-alt nav__icon"></i>Memberships
                        </a>
                    </li>

                    <li className="nav__item">
                        <a href="#contact" onClick={() => setActiveNav('#contact')} className=
                        {activeNav === "/contact" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-file-alt nav__icon"></i>Contact Us
                        </a>
                    </li>

                </ul>

                <i class="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
            </div>

            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                <i class="uil uil-apps"></i>
            </div>
        </nav>
    </header>
  )
}

export default Header