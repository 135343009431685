import React from 'react'

const Backend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Kids Brazilian Jiu Jitsu</h3>

        <div className="skills__box">
            <p>Our BJJ Kids program teaches your child the importance of discipline, 
            respect, and to protect themselves from bullies only when necessary. 
            Click the Learn More button to see our schedule!</p>
        </div>
        
        <button className="skills__button">Click here!</button>
    </div>
  )
}

export default Backend