import './App.css';
import Header from './components/header/Header'
import Home from './components/home/Home'
import About from './components/about/About'
import Skills from './components/skills/Skills'
import Services from './components/services/Services'
import Qualification from './components/qualification/Qualification'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import ScrollUp from './components/scrollup/ScrollUp';
import Waiver from './components/waiver/Waiver';
import Memberships from './components/memberships/Memberships';

function App() {
  return (
    <>
    <Header />
    <main className='main'>
      <Home />
      <Skills/>
      <About />
      <Services />
      <Qualification />
      <Memberships/>
      <Waiver/>
      <Contact />
    </main>
    <Footer />
    <ScrollUp />
    </>
  );
}

export default App;
