import React from 'react'
import One from '../../assets/5.jpg'

const OneMembership = () => {
  return (
    <div className="memberships__content">
        <h3 className="memberships__title">Adult Monthly Membership</h3>
        
        <div className="memberships__box">
          <img src={One} alt="" className="memberships__img"/>
        </div>

        <stripe-buy-button
        style={{marginTop: '30px'}}
          buy-button-id="buy_btn_1N6gYMAFFZn0NtX4tO2IbXcP"
          publishable-key="pk_live_51JcDQEAFFZn0NtX4NGzCVFYCq6uNPyDYsJ7TbeqiZJlwuhYRUhSw9QvM6Y1YGN3U9AzQUuu2qC1XjZxMazb25fT600wABPk2LA"
        >
        </stripe-buy-button>
    </div>
  )
}

export default OneMembership