import React, { useState } from 'react'
import "./services.css"
import Program1 from "../../assets/program_1.jpg"
import Program2 from "../../assets/program_2.jpg"

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }
  return (
    <section className="services section" id="services">
        <h2 className="section__title">Programs</h2>
        <span className="section__subtitle">What We Offer</span>

        <div className="services__container container grid">
            <div className="services__content">
                <div>
                    <i className="uil uil-head-side services__icon"></i>
                    <h3 className="services__title">Adult Brazilian<br /> Jiu Jitsu</h3>
                </div>
                <span className="services__button" onClick={() => toggleTab(1)}>
                    Learn More
                    <i className="uil uil-arrow-right services__button-icon"></i>
                </span>
                <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                        <h3 className="services__modal-title">Adult Brazilian Jiu Jutsu</h3>

                        <img src={Program1} alt="" className="services__img"/>

                        <p className="services__modal-description">Brazilian Jiu Jitsu, also known as “The Gentle Art”, 
                        is a martial art that utilizes leverage, your opponent’s strength, and their momentum against them. 
                        Brazilian Jiu Jitsu was designed to help everyone of every size, as well as body type, 
                        to defend themselves against an attacker. Students will learn how to fight on the ground by utilizing chokeholds, 
                        sweeps, and other restraining techniques to move an attacker off of them to gain control and superior resistance.
                        <br/><br/>
                        Brazilian Jiu Jitsu is a great way to sharpen self defense, but it’s also a fantastic full body cardio workout. 
                        Brazilian Jiu Jitsu’s regular training and rolling facilitates a body confidence on multiple levels, 
                        especially when combined with the maintenance of a healthy and balanced diet.
                        </p>

                        {/*<ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">I develop the user interface.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">Webpage development.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">I create a ux element interactions.</p>
                            </li>
                            
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">I position your company brand.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">Design and mockups of products for companies.</p>
                            </li>
  </ul>*/}
                    </div>
                </div>
            </div>


            <div className="services__content">
                <div>
                    <i className="uil uil-kid services__icon"></i>
                    <h3 className="services__title">Kids Brazilian <br/> Jiu Jitsu</h3>
                </div>
                <span className="services__button" onClick={() => toggleTab(2)}>
                    Learn More
                    <i className="uil uil-arrow-right services__button-icon"></i>
                </span>
                <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                        <h3 className="services__modal-title">Kids Brazilian Jiu Jutsu</h3>

                        <img src={Program2} alt="" className="services__img"/>

                        <p className="services__modal-description">Not only do we teach our younger students 
                        the same techniques we teach our adult students, but we also teach them the importance of taking 
                        responsibility for their actions, discipline, control, and help them build their own self confidence. 
                        Our instructors assist them in how to handle bullies with discipline and peaceful self confidence instead 
                        of escalating to an immediate physical altercation.</p>

                        {/*<ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">I develop the user interface.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">Webpage development.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">I create a ux element interactions.</p>
                            </li>
                            
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">I position your company brand.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">Design and mockups of products for companies.</p>
                            </li>
                        </ul>*/}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services