import React from 'react'
import "./memberships.css"
import One from './One'
import Two from './Two'
import Three from './Three'
import Four from './Four'

const Memberships = () => {
  return (
    <section className="memberships section" id="memberships">
      <h2 className="section__title">Memberships</h2>
        <span className="memberships__subtitle">If you’re new to Matlock BJJ, welcome! We are really happy and grateful that you want to become a part of our Jiu Jitsu family. Below, you will find our monthly membership offerings. Please select the membership that best suits your needs. However, if you are a family or have more than one child you would like to enter into our program, please contact us for our family rate instead of selecting one of the membership options below. You may contact us at (731) 571-5157.
        <br/><br/>
        If you are already a member and want to fix parts of your game that are not being directly touched on during class, please feel free to book a one on one session with Professor Turtle.</span>
        <div className="memberships__container container grid">
        <One />
        <Two />
        <Three/>
        <Four/>
        </div>
    </section>
  )
}

export default Memberships