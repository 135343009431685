import React from 'react'

const Frontend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Adult Brazilian Jiu Jitsu</h3>
        
        <div className="skills__box">
            <p>Are you looking for a fun way to lose weight, destress, 
            and learn how to protect yourself and your loved ones? 
            Click the Learn More button to see how we can help you unlock your best self!</p>
        </div>

        <button className="skills__button">Click here!</button>
    </div>
  )
}

export default Frontend